import React, { useState } from "react";
import Popup from "./PopUp";
import checkout from "../../../assets/svg/checkoutarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, getToken } from "../../../store/auth/selectors";
import { getFailedPayment } from "../../../store/app/selectors";
import useOMSQueries from "../../ReportsOMSQueries";
import { jwtDecode } from "jwt-decode";
import { InitializeRazorPay } from "../Razorpay";
import faildIcon from "../../../assets/svg/faildIcon.svg";
import { setFailedPayment } from "../../../store/app/app.slice";
import loader from "../../../assets/gif/newLoader.gif";
import { useWebEngageMutation } from "../../../store/ApiMutaion";

const PackageReviwPopup = ({ setIsPopupOpen, isPopupOpen, discount, examType }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const access_token = useSelector(getToken);
  const paymentRes = useSelector(getFailedPayment);
  const [isLoader, setIsLoader] = useState(false);
  const { handleCreatCartOrder, handleSuccessfulPayment, handleFaildPayment } = useOMSQueries();

  const [webEngage] = useWebEngageMutation();
  const payment_initiated = async () => {
    try {
      await webEngage({
        crn_id: `${userInfo?.crn_id}`,
        event_name: "payment_initiated",
        event_data: [
          { attribute: "package_name", value: `${discount?.discountForSubcriptionList[0]?.packages?.packageName}` },
          { attribute: "package_type", value: `Pro Package` },
          { attribute: "package_amount", value: `${discount?.discountForSubcriptionList[0]?.amount}` },
          {
            attribute: "grade",
            value: `${userInfo?.grade_id}`
          },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "discounted_amount", value: `${discount?.discountForSubcriptionList[0]?.afterDiscountAmount}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    dispatch(setFailedPayment({ isLoader: false, isFailed: false, res: null }));
  };

  const handleDecode = (access_token) => {
    try {
      const decodedToken = jwtDecode(access_token);
      return decodedToken;
    } catch (error) {
      console.error("Invalid JWT token", error);
    }
  };

  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleProceedtopayment = async () => {
    setIsLoader(true);
    payment_initiated();
    const token = await handleDecode(access_token);
    const packagedetails = {
      amountAfterDiscount: discount?.discountForSubcriptionList[0]?.afterDiscountAmount,
      totalPaidAmount: discount?.discountForSubcriptionList[0]?.afterDiscountAmount,
      batchId: null,
      amountPreGst: 0,
      billingAddress: {
        email: userInfo?.email,
        firstName: userInfo?.first_name,
        lastName: userInfo?.last_name,
        phone: userInfo?.phone,
        statecode: "36",
        zipcode: "500081",
        stateName: "Telangana",
        shipAddress: "",
        shipEmail: "",
        shipFirstName: "",
        shipLastName: "",
        shipPhone: "",
        shipStateCode: "",
        shipCity: "",
        shipStateName: "",
        shipZipCode: ""
      },
      discountAmount: discount?.discountForSubcriptionList[0]?.discountValue,
      discountCode:
        discount?.discountForSubcriptionList[0]?.code === "REPORT99"
          ? null
          : discount?.discountForSubcriptionList[0]?.code,
      notes: "",
      orderAmount: discount?.totalAmount,
      packageSubscriptions: [
        {
          id: discount?.discountForSubcriptionList[0]?.packageSubscription?.packageSubscriptionId,
          paymentPlanCaptureDetailsDtoList: [
            {
              paidAmount: discount?.discountForSubcriptionList[0]?.afterDiscountAmount,
              applicableAmount: discount?.discountForSubcriptionList[0]?.afterDiscountAmount,
              partPlanDetailsId:
                discount?.discountForSubcriptionList[0]?.packagePaymentPlanResponseDTO
                  ?.partPaymentPlanResponseV1DTOList[0]?.partPaymentDetailDTOList[0]?.partPaymentDetailId,
              dueDate: getFormattedDate()
            }
          ],
          packageDealPriceAfterDiscount: discount?.discountForSubcriptionList[0]?.afterDiscountAmount,
          actual_amount: discount?.totalAmount,
          package_discount_code: discount?.discountForSubcriptionList[0]?.code,
          package_discount_amount: discount?.discountForSubcriptionList[0]?.discountValue,
          quantity: 1,
          courseId: null,
          batchId: null,
          scoreId: null,
          liveClassCourseList: [],
          recordedCourse: false,
          recordedCourseList: [],
          specialDiscAmt: 0,
          specialDiscPer: 0
        }
      ],
      isdCode: userInfo?.isd_code,
      phone: userInfo?.phone,
      userPhoneNo: userInfo?.phone,
      payConditionDiscountAmount: 0,
      payConditionDiscountper: 0,
      paymentGatewayCode: "RAZORPAY",
      userId: token?.uam_id,
      userOrderMasterId: 0,
      userOrderPaymentPlanCaptureDetailsId: 0,
      quantity: 1,
      unitPrice: discount?.discountForSubcriptionList[0]?.afterDiscountAmount,
      renewed: false,
      extOrderId: 0,
      extDays: 0,
      grade: userInfo?.grade,
      token: null,
      modeofPayment: "RAZORPAY",
      emiPatner: "",
      installmentAmount: 0,
      transactionAmount: 0,
      transactionType: null,
      noOfMonth: null,
      payLatterPatner: null,
      cardNo: null,
      bajajMobileNo: null,
      tanure: null,
      schemeId: null,
      redirectionUrl: "https://inmobiusinfinitylearn.com/home",
      specialDiscAmt: 0,
      specialDiscPer: 0,
      applicantPhoneNo: userInfo?.phone,
      portal: "inmobius",
      userPlatform: "inmobius"
    };
    // Check if the amount after discount is present
    if (packagedetails.amountAfterDiscount) {
      const order = await handleCreatCartOrder(packagedetails);
      localStorage.setItem("userOrderDetailes", JSON.stringify(order));
      if (order.orderId) {
        const fullPayment =
          discount?.discountForSubcriptionList[0]?.packagePaymentPlanResponseDTO?.partPaymentPlanResponseV1DTOList?.find(
            (p) => p?.numParts === 1
          );
        const partFullPayment = fullPayment?.partPaymentDetailDTOList?.[0];
        const fullPaymentOptions = {
          ...partFullPayment,
          amount: fullPayment?.amountAfterAdditionalDiscount,
          discountPer: fullPayment?.discountForThisPartPayment
        };
        const userDetails = {
          firstName: userInfo.first_name,
          lastName: userInfo.last_name,
          email: userInfo.email,
          phone: userInfo.phone,
          grade: userInfo.grade,
          userId: token?.uam_id
        };
        await InitializeRazorPay(order, fullPaymentOptions, userDetails, handleSuccessfulPayment, handleFaildPayment);
        setIsLoader(false);
      } else {
        console.error("Order creation failed: Invalid order response", order);
        throw new Error("Order creation failed. Please try again later.");
      }
    }
  };

  return (
    <div>
      <Popup show={isPopupOpen} onClose={closePopup} ispdf={false}>
        <div className={!isLoader && !paymentRes?.isLoader ? "Rep_PackageReviwPopup" : "Rep_PackageReviwPopup blurred"}>
          <div className="Rep_packagecont">
            {!paymentRes?.isFailed ? (
              <h4>Review Order and Checkout</h4>
            ) : (
              <>
                <img className="Rep_faildIcon" src={faildIcon} alt="faildIcon" />
                <h3 className="Rep_faildtitle">Payment failed!</h3>
                <p className="Rep_failddes">{paymentRes?.res?.failureMessage}</p>
              </>
            )}

            <div className="Rep_packagecontInner">
              <div className="Rep_packageName">
                <h5>{examType?.toUpperCase()}</h5>
                <p>Advanced Report</p>
              </div>
              <div className="Rep_line"></div>
              <div className="Rep_packagePricing">
                <h5>₹{Math.floor(discount?.totalAmountAfterDiscount)}</h5>
                <span className="Rep_discountPrice">₹{discount?.totalAmount}</span>
                <span className="Rep_discount">{Math.floor(discount?.percentageOrAmount)}% discount!</span>
              </div>
            </div>
          </div>
          <div className="Rep_BillingCont">
            <div className="Rep_Billinginfo">
              <h6>Billing Information</h6>
              <div className="Rep_BillinginfoIN">
                {" "}
                <div className="Rep_flotingfablecont">
                  <div className="Rep_flotingfable">
                    <label className="Rep_flotingLable">
                      name<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="Rep_flotingLableinput "
                      value={
                        userInfo?.first_name
                          ? userInfo.first_name + (userInfo?.last_name ? " " + userInfo.last_name : "")
                          : ""
                      }
                      required
                    />
                  </div>
                </div>
                <div className="Rep_flotingfablecont">
                  <div className="Rep_flotingfable">
                    <label className="Rep_flotingLable">
                      phone nuber <span>*</span>
                    </label>
                    <p className="Rep_countryCode">+91</p>
                    <input type="text" className="Rep_flotingLableinput " value={userInfo?.phone} required />
                  </div>
                </div>
              </div>
            </div>
            <div className="Rep_BillingSummary">
              <div className="Rep_BillingSummaryCont">
                <h6>Billing Summary</h6>
                <div className="Rep_Subotal">
                  <p>subtotal</p> <h5>₹{Math.floor(discount?.totalAmountAfterDiscount)}</h5>
                </div>
                <div className="Rep_SuparatorHline"></div>
                <div className="Rep_Subotal">
                  <h6>subtotal</h6> <h5>₹{Math.floor(discount?.totalAmountAfterDiscount)}</h5>
                </div>
              </div>
              <button onClick={handleProceedtopayment} className="Rep_checkoutbtn">
                {!paymentRes?.isFailed ? "proceed to checkout" : "Try Again"}
                <span>
                  <img src={checkout} alt="checkout" />
                </span>
              </button>
            </div>
          </div>
        </div>
        {(isLoader || paymentRes?.isLoader) && <img className="Rep_loader_cont" src={loader} alt="loader" />}
      </Popup>
    </div>
  );
};

export default PackageReviwPopup;
