import { CONFIG_VARS } from "../constants/configuration";
import {
  useGetSchoolDetailsMutation,
  useLsqONsuccesPaymentMutation,
  useTalkToExpertActivityMutation
} from "../store/ApiMutaion";
import { getDeviceAndLocationPayload } from "../utils/getDeviceDetails.utils";
import { getUserInfo } from "../store/auth/selectors";
import { useSelector } from "react-redux";
const useLeadCapture = () => {
  const [schoolDetails] = useGetSchoolDetailsMutation();
  const [lsqOnSuccessPayment] = useLsqONsuccesPaymentMutation();
  const [talkToExpertActivity] = useTalkToExpertActivityMutation();
  const actualscore = localStorage.getItem("url");
  const userInfo = useSelector(getUserInfo);
  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
  const handleLoginLeadCaptur = async (data) => {
    try {
      const res = schoolDetails(data).unwrap();
      return res;
    } catch (error) {
      console.error("Error pushing LSQ event:", error);
    }
  };

  const handleLeadCapture = async (data) => {
    if (CONFIG_VARS.environment === "production") {
      const res = await handleLoginLeadCaptur(data?.user?.school_id);
      const payload = await getDeviceAndLocationPayload();
      const body = {
        type: "ACTIVITY",
        mode: "LSQ",
        env: CONFIG_VARS.environment,
        ActivityEvent: 213,
        phone: data?.user?.phone,
        ActivityDateTime: formattedDate, //"2025-01-16 06:41:17",
        FirstName: data?.user?.first_name,
        LastName: data?.user?.last_name,
        GradeID: data?.user?.grade_id,
        custom_attribute_fields: {
          mx_Mobile_device: payload?.device_attributes
        },
        LeadFields: {
          mx_Actual_source_URL: "https://inmobiusinfinitylearn.com/",
          mx_Student_Login_ID: data?.user?.admission_id,
          mx_Grade_1: data?.user?.grade_id,
          mx_Target_Exam_1:
            data?.user?.grade_id >= 1 && data?.user?.grade_id <= 5
              ? "Foundation Test"
              : data?.user?.grade_id >= 6 && data?.user?.grade_id <= 8
              ? "Aptitude Test"
              : data?.user?.grade_id >= 9
              ? ""
              : "Invalid Grade",
          mx_Primary_Target_Exam:
            data?.user?.grade_id >= 1 && data?.user?.grade_id <= 5
              ? "Foundation Test"
              : data?.user?.grade_id >= 6 && data?.user?.grade_id <= 8
              ? "Aptitude Test"
              : data?.user?.grade_id >= 9
              ? ""
              : "Invalid Grade", // 1-5 Foundation Test. 6-8 Aptitude Test. 9+ ""
          mx_Inmobius_School_Code: data?.user?.school_id,
          mx_School_Name: res?.data?.school_name,
          mx_Board: res?.data?.board,
          mx_Street1: res?.data?.address,
          mx_Inmobius_School_Address: res?.data?.address,
          mx_City: payload.city === "N/A" ? res?.data?.city : payload.city, // IP city, if null send school city
          mx_Student_City: res?.data?.city,
          mx_School_City: res?.data?.city,
          mx_State: payload.state === "N/A" ? res?.data?.state : payload.state, // IP state, if null send school state
          mx_School_State: res?.data?.state,
          mx_Country: res?.data?.country,
          mx_Zip: res?.data?.pincode ?? payload.pincode, // IP zip, if null send school zip
          mx_School_Pincode: res?.data?.pincode,
          mx_PinCode: res?.data?.pincode,
          mx_Assessment_Date: "", // ""
          mx_CAR_Link: "", // ""
          mx_Certificate: "", // ""
          mx_Fees: res?.data?.fee,
          mx_Tier: res?.data?.tier,
          mx_Category: res?.data?.category,
          mx_CX_Category: res?.data?.category
        },
        ActivityFields: {
          mx_Custom_1: actualscore, // landing page: // user source
          mx_Custom_2: payload?.webType, // medium: Web/MWeb (check with chithra for exact word)
          mx_Custom_3: "InMobius" // product: InMobius
        },
        Source: "InMobius Leads"
      };
      // console.log(body);
      try {
        await lsqOnSuccessPayment(body);
      } catch (error) {
        console.error("Error pushing LSQ event:", error);
      }
    }
  };

  const handelAlerbarLead = async (param) => {
    const payload = await getDeviceAndLocationPayload();
    const body = {
      type: "ACTIVITY",
      mode: "LSQ",
      env: CONFIG_VARS.environment,
      ActivityEvent: 354,
      phone: userInfo?.phone,
      ActivityDateTime: formattedDate,
      FirstName: userInfo?.first_name,
      LastName: userInfo?.last_name,
      GradeID: userInfo?.grade_id,
      ActivityFields: {
        mx_Custom_1: userInfo?.grade_id,
        mx_Custom_2: "InMobius",
        mx_Custom_3: payload?.webType,
        mx_Custom_4: payload.latitude,
        mx_Custom_5: payload?.longitude,
        mx_Custom_6: payload?.ip_address,
        mx_Custom_7: param?.img_link || "",
        mx_Custom_8: param?.redirect_link || "",
        mx_Custom_9: userInfo.school_id,
        mx_Custom_10: param?.source || ""
      },
      Source: "InMobius Leads"
    };
    try {
      await lsqOnSuccessPayment(body);
    } catch (error) {
      console.error("Error pushing LSQ event:", error);
    }
  };

  const formatDateTime = (date, time, userIsdCode) => {
    try {
      const months = {
        jan: 0,
        feb: 1,
        mar: 2,
        apr: 3,
        may: 4,
        jun: 5,
        jul: 6,
        aug: 7,
        sep: 8,
        oct: 9,
        nov: 10,
        dec: 11
      };

      const [month, day] = date.split(" ");
      const year = new Date().getFullYear();
      const [hour, period] = time.split(" ");

      // Convert to 24-hour format
      let hour24 = parseInt(hour);
      if (period.toLowerCase() === "pm" && hour24 !== 12) {
        hour24 += 12;
      } else if (period.toLowerCase() === "am" && hour24 === 12) {
        hour24 = 0;
      }

      // Create date using direct values
      const monthIndex = months[month.toLowerCase()];
      if (monthIndex === undefined) {
        throw new Error(`Invalid month: ${month}`);
      }

      const dateObj = new Date(year, monthIndex, parseInt(day), hour24, 0, 0);
      if (isNaN(dateObj.getTime())) {
        throw new Error("Invalid date created");
      }

      // Only adjust time for UAE users (ISD code +971)
      const adjustedDate = userIsdCode === "+971" ? new Date(dateObj.getTime() - 5.5 * 60 * 60 * 1000) : dateObj;

      return {
        activityDateTime: dateObj.toISOString().split(".")[0],
        scheduledDateTime: adjustedDate.toISOString().split(".")[0].replace("T", " ")
      };
    } catch (error) {
      console.error("Error formatting date time:", error);
      throw error;
    }
  };

  const handleTalkToExpertLeadCapture = async ({
    user,
    selectedDate,
    selectedTime,
    selectedAttendees,
    schoolName,
    deviceLocation
  }) => {
    const { activityDateTime, scheduledDateTime } = formatDateTime(selectedDate, selectedTime, user?.isd_code);

    const payload = {
      type: "ACTIVITY",
      mode: "LSQ",
      env: CONFIG_VARS.environment,
      ActivityEvent: 266,
      phone: user?.phone,
      ActivityDateTime: activityDateTime,
      FirstName: user?.first_name,
      LastName: user?.last_name || "",
      GradeID: user?.grade_id,
      ActivityFields: {
        mx_Custom_2: user?.last_name || "",
        mx_Custom_3: user?.first_name,
        mx_Custom_6: scheduledDateTime,
        mx_Custom_7: selectedAttendees.join(","),
        mx_Custom_12: user?.exam_type,
        mx_Custom_13: user?.grade_id,
        mx_Custom_20: schoolName || "",
        mx_Custom_21: user?.school_id,
        mx_Custom_22: deviceLocation?.latitude || "N/A",
        mx_Custom_23: deviceLocation?.longitude || "N/A",
        mx_Custom_24: "InMobius",
        mx_Custom_25: deviceLocation?.webType || "Web"
      },
      Source: "InMobius Leads"
    };

    return await talkToExpertActivity(payload);
  };

  return {
    handleLeadCapture,
    handelAlerbarLead,
    handleTalkToExpertLeadCapture
  };
};

export default useLeadCapture;
