import {
  useGetOmsPackagesMutation,
  useGetMySubscriptionsMutation,
  useGetAllCartItemsMutation,
  useDeleteFromCartMutation,
  useSaveCartItemMutation,
  useCalculateDiscountMutation,
  useCreateUserCartOrderMutation,
  usePaymentABortedbyUserMutation,
  useUserSuccesPaymentMutation
} from "../store/OmsMutation";
import { useLsqONsuccesPaymentMutation, useWebEngageMutation } from "../store/ApiMutaion";

import { useSelector, useDispatch } from "react-redux";
import { getUserInfo, getToken, getSelectedEventsDetails } from "../store/auth/selectors";
import { setFailedPayment } from "../store/app/app.slice";
import { jwtDecode } from "jwt-decode";
import { CONFIG_VARS } from "../constants/configuration";

const useOMSQueries = () => {
  const userInfo = useSelector(getUserInfo);
  const access_token = useSelector(getToken);
  const examdetailes = useSelector(getSelectedEventsDetails);

  const [fetchPackages] = useGetOmsPackagesMutation();
  const [fetchMySubscriptions] = useGetMySubscriptionsMutation();
  const [fetchAllCartItems] = useGetAllCartItemsMutation();
  const [clearCart] = useDeleteFromCartMutation();
  const [saveItemTOCart] = useSaveCartItemMutation();
  const [calculateDiscount] = useCalculateDiscountMutation();
  const [creatCartOrder] = useCreateUserCartOrderMutation();
  const [sucess] = useUserSuccesPaymentMutation();
  const [faild] = usePaymentABortedbyUserMutation();
  const [webEngage] = useWebEngageMutation();
  const [lsqONsuccesPayment] = useLsqONsuccesPaymentMutation();
  const dispatch = useDispatch();
  const handleDecode = (access_token) => {
    try {
      const decodedToken = jwtDecode(access_token);
      return decodedToken;
    } catch (error) {
      console.error("Invalid JWT token", error);
    }
  };

  const handleFetchOMSPackages = async (packageID) => {
    try {
      const data = await fetchPackages(packageID).unwrap();
      return data;
    } catch (error) {
      console.log(`failed: ${error.message}`);
      // if (retryCount < 3) {
      //   setTimeout(() => {
      //     handleFetchOMSPackages(examId, producttype, grade, retryCount + 1);
      //   }, 1000);
      // } else {
      //   console.log("Max retry attempts reached. Could not fetch the exam report.");
      // }
    }
  };

  const handleFetchMySubscriptions = async (grade, userId) => {
    try {
      const data = await fetchMySubscriptions({ grade, userid: userId }).unwrap();
      return data;
    } catch (error) {
      console.log("Error fetching my subscriptions", error);
    }
  };
  const handleFetchAllCartItems = async (grade, userId) => {
    try {
      const data = await fetchAllCartItems({ userId, grade }).unwrap();
      return data;
    } catch (error) {
      console.log("Error fetching my subscriptions", error);
    }
  };

  const handleClearCart = async (cartID) => {
    try {
      const data = await clearCart(cartID).unwrap();
      return data;
    } catch (error) {
      console.log("Error clearing cart", error);
    }
  };
  const handleSaveItemTOCart = async (data) => {
    try {
      const res = await saveItemTOCart(data).unwrap();
      return res;
    } catch (error) {
      console.log("Error saving item to cart", error);
    }
  };
  const handleCalculateDiscount = async (coupons, grade, userID) => {
    try {
      const res = await calculateDiscount({ coupons, grade, userID }).unwrap();
      return res;
    } catch (error) {
      console.log("Error calculating discount", error);
    }
  };

  const handleCreatCartOrder = async (data) => {
    try {
      const res = await creatCartOrder(data).unwrap();
      return res;
    } catch (error) {
      console.log("Error creating cart order", error);
    }
  };
  const paymentStatus = async (userInfo, name, data, currentExam, orderDetails) => {
    try {
      await webEngage({
        crn_id: `${userInfo?.crn_id}`,
        event_name: `${name}`,
        event_data: [
          { attribute: "package_name", value: `${data?.data?.discountForSubcriptionList[0]?.packages?.packageName}` },
          { attribute: "package_type", value: `Pro Package` },
          { attribute: "package_amount", value: `${data?.data?.discountForSubcriptionList[0]?.amount}` },
          {
            attribute: "grade",
            value: `${userInfo?.grade_id}`
          },
          { attribute: "Platform", value: "InMobius" },
          {
            attribute: "discounted_amount",
            value: `${data?.data?.discountForSubcriptionList[0]?.afterDiscountAmount}`
          },
          {
            attribute: "CAR_report_detailed_link",
            value: `${CONFIG_VARS.report_link}${currentExam}-report/${userInfo?.crn_id}/${examdetailes?.event_id}`
          },
          { attribute: "order_id", value: ` ${orderDetails?.orderId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const lsqPaymentSuccess = async (discountPrice) => {
    const orderDetails = await JSON.parse(localStorage.getItem("pacakegeDetails"));
    const couponCode = await JSON.parse(localStorage.getItem("couponCode"));
    try {
      await lsqONsuccesPayment({
        type: "ACTIVITY",
        mode: "LSQ",
        env: CONFIG_VARS.environment,
        ActivityEvent: 257,
        phone: userInfo?.phone,
        FirstName: userInfo?.first_name,
        LastName: userInfo?.last_name,
        GradeID: userInfo?.grade_id,
        ActivityFields: {
          mx_Custom_1: orderDetails?.data?.packageName,
          mx_Custom_2: orderDetails?.data?.packageSubscriptions[0]?.packagePaymentPlan?.mrp,
          mx_Custom_3: couponCode,
          mx_Custom_4: discountPrice?.data?.discountForSubcriptionList[0]?.afterDiscountAmount,
          mx_Custom_5: new Date(),
          mx_Custom_6: "Inmobius"
        },
        Source: "InMobius Leads"
      });
    } catch (error) {
      console.error("Error pushing Lsq event:", error);
    }
  };

  const handleFaildPayment = async (response = {}) => {
    dispatch(setFailedPayment({ isLoader: true, isFailed: true, res: null }));

    try {
      const discountPrice = await JSON.parse(localStorage.getItem("discountPrice"));
      const orderDetails = await JSON.parse(localStorage.getItem("userOrderDetailes"));
      const defaultReason = "Payment processing cancelled by user";
      const reason = response.reason || defaultReason;
      const currentExam = examdetailes?.title?.toLowerCase().replace(/[\s\W]+/g, "");
      paymentStatus(userInfo, "payment_failed", discountPrice, currentExam, orderDetails);
      const data = {
        amount: discountPrice?.data?.discountForSubcriptionList[0]?.afterDiscountAmount,
        creationDate: new Date(),
        status: "aborted",
        userId: access_token?.uam_id,
        userOrderId: orderDetails?.orderId,
        grade: userInfo?.grade,
        reason: reason,
        description: reason
      };
      const res = await faild(data).unwrap();
      dispatch(setFailedPayment({ isLoader: false, isFailed: true, res: res }));
    } catch (error) {
      console.error("Error during failed payment handling:", error);
    }
  };

  const handleSuccessfulPayment = async (response) => {
    dispatch(setFailedPayment({ isLoader: true, isFailed: false, res: null }));

    const discountPrice = await JSON.parse(localStorage.getItem("discountPrice"));
    const orderDetails = await JSON.parse(localStorage.getItem("userOrderDetailes"));
    const currentExam = examdetailes?.title?.toLowerCase().replace(/[\s\W]+/g, "");
    paymentStatus(userInfo, "payment_successful", discountPrice, currentExam, orderDetails);
    lsqPaymentSuccess(discountPrice);
    const data = {
      gatewayCode: "RAZORPAY",
      paymentMode: "ONLINE",
      razorpayOrderId: response?.razorpay_order_id,
      razorpayPaymentId: response?.razorpay_payment_id,
      razorpaySignature: response?.razorpay_signature,
      userOrderId: orderDetails?.orderId,
      ruleID: discountPrice?.data?.discountForSubcriptionList[0]?.packages?.packageId,
      grade: userInfo?.grade_id,
      userId: access_token?.uam_id,
      userOrderPaymentPlanCaptureDetailsRequest: [
        {
          paidAmount: discountPrice?.data?.discountForSubcriptionList[0]?.afterDiscountAmount,
          partAmount: discountPrice?.data?.discountForSubcriptionList[0]?.afterDiscountAmount
        }
      ],
      portal: "inmobius",
      studentId: access_token.uam_id,
      tenantID: "infinitylearn"
    };
    try {
      const res = await sucess(data).unwrap();
      if (res) {
        dispatch(setFailedPayment({ isLoader: false, isFailed: false, res: res }));
        window.location.reload();
      }
      return res;
    } catch (error) {
      console.log("Error payment successful", error);
    }
  };

  const handlePaymentFlow = async (initialTimeLeft, currentExam, examDetails) => {
    // Map of package IDs based on currentExam and examDetails.level

    const coupon =
      initialTimeLeft <= 86400 && initialTimeLeft > 0 ? "REPORT29" : initialTimeLeft === 0 ? "REPORT49" : null;
    localStorage.setItem("couponCode", JSON.stringify(coupon));

    try {
      const token = await handleDecode(access_token);
      const grade = `Grade ${userInfo?.grade_id}`;
      const uamId = token?.uam_id;
      const packageId = CONFIG_VARS?.packageMapping[`${currentExam}${examDetails?.level}`];

      // Fetch available packages and student purchases
      const availablePackages = await handleFetchOMSPackages(packageId);
      localStorage.setItem("pacakegeDetails", JSON.stringify(availablePackages));

      const studentPurchases = await handleFetchMySubscriptions(userInfo.grade_id, uamId);

      const packageIdExists = studentPurchases?.some((itme) => itme.mySubcriptionDtoList[0]?.packageId == packageId);
      if (packageIdExists) {
        return true;
      }
      // Clear existing cart items if necessary
      const checkCart = await handleFetchAllCartItems(grade, uamId);
      if (checkCart?.data?.length > 0) {
        await Promise.all(checkCart.data.map((item) => handleClearCart(item.id)));
      }

      // Prepare package data for saving to cart
      const packageData = {
        amount: availablePackages?.data?.packageSubscriptions[0]?.packagePaymentPlan?.mrp,
        batchId: "",
        courseId: "",
        createdBy: `${userInfo?.first_name} ${userInfo?.last_name}`,
        grade,
        id: availablePackages?.data?.packageId,
        liveClassCourseList: [],
        modifiedBy: "",
        oldSubscriptionId: 0,
        oldUserOrderMasterId: 0,
        priority: 0,
        recordedCourse: false,
        recordedCourseList: [],
        renewal: false,
        scoreId: "",
        subscriptionId: availablePackages?.data?.packageSubscriptions[0]?.packagePaymentPlan?.packageSubscription,
        subscriptionRenewed: false,
        userId: uamId
      };

      // Save package data to cart
      const saveToCartResponse = await handleSaveItemTOCart(packageData);

      // Apply discount if the item was saved to cart
      if (saveToCartResponse?.data) {
        const discountPrice = await handleCalculateDiscount(coupon, grade, uamId);
        localStorage.setItem("discountPrice", JSON.stringify(discountPrice));
        return discountPrice?.data;
      }

      return null;
    } catch (error) {
      console.error("Error in payment flow:", error);
      return null;
    }
  };

  const handleSelectedPaymentFlow = async (currentExam, examDetails) => {
    // Map of package IDs based on currentExam and examDetails.level

    const coupon = "REPORT99";
    localStorage.setItem("couponCode", JSON.stringify(coupon));

    try {
      const token = await handleDecode(access_token);
      const grade = `Grade ${userInfo?.grade_id}`;
      const uamId = token?.uam_id;
      const packageId = CONFIG_VARS?.packageMapping[`${currentExam}${examDetails?.level}`];

      // Fetch available packages and student purchases
      const availablePackages = await handleFetchOMSPackages(packageId);
      localStorage.setItem("pacakegeDetails", JSON.stringify(availablePackages));

      const studentPurchases = await handleFetchMySubscriptions(userInfo.grade_id, uamId);

      const packageIdExists = studentPurchases?.some((itme) => itme.mySubcriptionDtoList[0]?.packageId == packageId);
      if (packageIdExists) {
        return true;
      }
      // Clear existing cart items if necessary
      const checkCart = await handleFetchAllCartItems(grade, uamId);
      if (checkCart?.data?.length > 0) {
        await Promise.all(checkCart.data.map((item) => handleClearCart(item.id)));
      }

      // Prepare package data for saving to cart
      const packageData = {
        amount: availablePackages?.data?.packageSubscriptions[0]?.packagePaymentPlan?.mrp,
        batchId: "",
        courseId: "",
        createdBy: `${userInfo?.first_name} ${userInfo?.last_name}`,
        grade,
        id: availablePackages?.data?.packageId,
        liveClassCourseList: [],
        modifiedBy: "",
        oldSubscriptionId: 0,
        oldUserOrderMasterId: 0,
        priority: 0,
        recordedCourse: false,
        recordedCourseList: [],
        renewal: false,
        scoreId: "",
        subscriptionId: availablePackages?.data?.packageSubscriptions[0]?.packagePaymentPlan?.packageSubscription,
        subscriptionRenewed: false,
        userId: uamId
      };

      // Save package data to cart
      const saveToCartResponse = await handleSaveItemTOCart(packageData);
      // Apply discount if the item was saved to cart
      if (saveToCartResponse?.data) {
        const discountPrice = await handleCalculateDiscount(coupon, grade, uamId);
        localStorage.setItem("discountPrice", JSON.stringify(discountPrice));
        return discountPrice?.data;
      }

      return null;
    } catch (error) {
      console.error("Error in payment flow:", error);
      return null;
    }
  };

  return {
    handlePaymentFlow,
    handleCreatCartOrder,
    handleFaildPayment,
    handleSuccessfulPayment,
    handleSelectedPaymentFlow
  };
};

export default useOMSQueries;
