import React from "react";
import "./TalkToExpertBanner.css";
import reportsPageBanner from "../../../assets/Banners/reportsPageTalkToExpertBanner.svg";
import reportsPageBannerMob from "../../../assets/Banners/reportsPageTalkToExpertBannerMob.svg";

export const TalkToExpertBanner = ({ onBannerClick }) => {
  return (
    <>
      <div className="banner-column">
        <picture>
          <img src={reportsPageBanner} alt="Talk to Expert Banner" className="banner-image" onClick={onBannerClick} />
        </picture>
      </div>

      <div className="banner-trigger" onClick={onBannerClick}>
        <img src={reportsPageBannerMob} alt="Talk to Expert Banner" className="banner-image" />
      </div>
    </>
  );
};
