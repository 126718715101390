import React from "react";
import whatsapp from "../../../assets/svg/whatsapp-icon.svg";
import useLeadCapture from "../../../pages/UseLeadCapture";

const WhatsAppButton = () => {
  const { handelAlerbarLead } = useLeadCapture();

  const handleClick = () => {
    const params = {
      source: "whatsapp_icon"
    };
    handelAlerbarLead(params);
    const whatsappUrl = `https://bit.ly/4iIAD2n`;
    window.open(whatsappUrl, "_blank");
  };

  return <img src={whatsapp} onClick={handleClick} alt="Whatsapp" />;
};

export default WhatsAppButton;
