import React, { useEffect, useState } from "react";
import { TalkToExpertModal } from "./components/modal/TalkToExpertModal";
import { TalkToExpertBanner } from "./components/talkToExpertBanner/TalkToExpertBanner";
import Guid from "../assets/svg/olampiad/guid.svg";
import back from "../assets/svg/reportspage/leftarow.svg";
import leftArrow from "../assets/svg/reportspage/LeftArrow.svg";
import certific from "../assets/svg/reportspage/certificateicon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ROUTES } from "../constants/routes";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails, getToken } from "../store/auth/selectors";
import { useAdvanceReportMutation, useExamStatusMutation, useWebEngageMutation } from "../store/ApiMutaion";
import { format, parseISO } from "date-fns";
import { CONFIG_VARS } from "../constants/configuration";

export const OlympaidReport = () => {
  const [reportData, setReportData] = useState(null);

  const [showTalkExpert, setShowTalkExpert] = useState(false);

  const handleBannerClick = () => {
    setShowTalkExpert(true);
  };

  const handleCloseTalkExpert = () => {
    setShowTalkExpert(false);
  };
  const examdetailes = useSelector(getSelectedEventsDetails);
  const [fetchAdvancereport] = useAdvanceReportMutation();
  const currentExam = examdetailes?.title?.toLowerCase().replace(/\s+/g, "");
  const [formetDate, setFormetData] = useState();
  const [fetchExamStatus] = useExamStatusMutation();
  const [webEngage] = useWebEngageMutation();
  const examId = examdetailes?.exam_id;
  const history = useHistory();
  const access_token = useSelector(getToken);
  const handleFetchExamStatus = async (examId) => {
    try {
      let submittedAt;
      if (examdetailes.submitedAt) {
        const utcDate = parseISO(examdetailes.submitedAt);
        submittedAt = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000 - 48 * 1000);
      } else {
        submittedAt = parseISO((await fetchExamStatus(examId)).data.submitted_at);
      }
      setFormetData(format(submittedAt, "dd MMMM yyyy"));
    } catch (error) {
      console.error("Error fetching exam status:", error.message);
    }
  };
  const handelAdvancedReport = async (examId, event) => {
    try {
      const response = await fetchAdvancereport({ examId: examId, event: event });
      setReportData(response?.data?.advanced_report);
    } catch (error) {
      console.error("Error fetching report:", error);
      return null;
    }
  };
  useEffect(() => {
    handelAdvancedReport(examdetailes.exam_id, currentExam);
    handleFetchExamStatus(examdetailes.exam_id);
  }, []);
  const getColor = (score) => {
    if (score >= 0 && score <= 30) {
      return "#B7295A";
    } else if (score > 30 && score <= 60) {
      return "#C4A200";
    } else if (score > 60 && score <= 80) {
      return "#00966D";
    } else if (score > 80 && score <= 100) {
      return "#23628D";
    }
  };

  const getStanineColor = (score) => {
    if (score >= 1 && score <= 3) {
      return "#B7295A";
    } else if (score > 3 && score <= 6) {
      return "#C4A200";
    } else if (score > 6 && score <= 8) {
      return "#00966D";
    } else if (score === 9) {
      return "#23628D";
    }
  };
  const CertificateWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "Download_Certificate_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.StudentExamType + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };

  const getDotDisplay = (score) => {
    switch (score) {
      case "Low":
        return ["block", "none", "none"];
      case "Medium":
        return ["none", "block", "none"];
      case "High":
        return ["none", "none", "block"];
    }
  };
  const handleBackButtonClick = () => {
    history.push(ROUTES.DASHBOARD);
  };
  const handleDownloadCertificet = () => {
    const url = `${CONFIG_VARS.certficateUrl}/${examdetailes.crn_id}/${examdetailes.event_id}.pdf`;
    CertificateWebEngage();
    window.open(url);
  };
  const ViewSollutionWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "View_Solution_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.title + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const handleViewSolutions = async () => {
    await ViewSollutionWebEngage();
    const baseUrl = `${CONFIG_VARS.scoreViewreportUrl}/test/viewsolutionspage?test_type=e34d1d14-6c33-48fd-900e-e8d3cb76da64`;
    const examId = examdetailes.exam_id;
    const qu_id = examdetailes.qu_id;
    const token = access_token.replace("Bearer ", "");
    const url = `${baseUrl}&question_paper_id=${qu_id}&token=${token}&exam_id=${examId}`;
    // window.open(url);
    window.open(url, "_self", "noreferrer");
  };
  return (
    <div className="Rep_main_cont">
      <TalkToExpertModal isOpen={showTalkExpert} onClose={handleCloseTalkExpert} />
      <div className="Rep_btn-cont">
        <button onClick={handleBackButtonClick} className="Rep_back-btn">
          <span>
            <img src={back} />
          </span>
          back
        </button>
        <div className="Rep_back-btn2">
          <div className="Rep_back-btn2In">
            {" "}
            <img onClick={handleBackButtonClick} src={leftArrow} /> <p>Report</p>
          </div>
        </div>
        <button onClick={handleViewSolutions} className="attempt-btn Rep_attempt-btn1">
          View Solutions
        </button>
      </div>
      <div className="rep-whole-cont" style={{ marginTop: "20px" }}>
        <TalkToExpertBanner onBannerClick={handleBannerClick} />
        <div style={{ flex: 1 }}>
          <h1 className="Rep_exam-title">
            {examdetailes?.title} {examdetailes?.level} | {formetDate}
          </h1>
          <div className="cetrificate-cont">
            <div className="Rep-text-cont">
              <img src={certific} alt="note" className="mock_img mock_imgweb" />
              <div className="Rep_mock-Cont">
                <h2 className="mock-text Rep_mock-text">Test Completed</h2>
                <p className="mock-para Rep_mock-para ">Celebrate your achievement! Download your certificate now.</p>
              </div>
              <img src={certific} alt="note" className="mock_img mock_imgmob" />
            </div>
            <button onClick={handleDownloadCertificet} className="attempt-btn Rep_attempt-btn2">
              Download Certificate
            </button>
          </div>
          <h3 className="Rep_sub-head">Prelim Summary</h3>
          <div className="MT-reports">
            <div className="Mt-reportHeader">
              <p className="Mt-SkillHeader">Skill</p>
              <p className="Mt-SubSkillHeader">Sub-Skill</p>
              <p className="Mt-ScoreHeader">Score %</p>
              <p className="Mt-StanineHeader">Stanine</p>
            </div>
            {reportData?.map((skillGroup, index) =>
              Object?.keys(skillGroup)?.map((skillName) => (
                <div key={index} className="Mt-reportbody">
                  <div className="Mt-Skillbody">{skillName}</div>
                  <div style={{ background: "#E2E2E2", width: "1px" }}></div>
                  <div className="Mt-SubSkillcont">
                    {Object?.entries(skillGroup[skillName]?.subskills)?.map(([subSkillName, subSkillData], index) => (
                      <div key={index} className="Mt-inSkillcont">
                        <div className="Mt-subskillbody">{subSkillName?.trim()}</div>
                        <div
                          className="Mt-Scorebody"
                          style={{
                            color: getColor(subSkillData?.subskill_score)
                          }}
                        >
                          {subSkillData?.subskill_score}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ background: "#E2E2E2", width: "1px" }}></div>
                  <div
                    className="Mt-Staninebody"
                    style={{
                      color: getStanineColor(skillGroup[skillName]?.stanine_score)
                    }}
                  >
                    {skillGroup[skillName]?.stanine_score}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="MT-reportsGuide">
            <p>
              <span>Note:</span> A stanine (“Standard Nine”) score is a way to scale scores on a nine-point scale.
            </p>
            <img src={Guid} alt="Guide" />
          </div>
          <h3 className="Rep_sub-head">Proficiency</h3>
          <div className="MT-reportsProfi">
            <div className="Mt-reportHeader">
              <p className="Mt-Sub-SkillHeader">Sub-Skill</p>
              <p className="Mt-medHeader">Low</p>
              <p className="Mt-medHeader">Med</p>
              <p className="Mt-medHeader">High</p>
            </div>

            {reportData?.map((skillGroup) =>
              Object?.keys(skillGroup)?.map((skillName) =>
                Object?.entries(skillGroup[skillName]?.subskills)?.map(([subSkillName, subSkillData], index) => {
                  const [lowDisplay, midDisplay, highDisplay] = getDotDisplay(subSkillData?.subskill_proficiency);
                  return (
                    <div key={index} className="Mt-reportbody">
                      <div className="Mt-Sub-Skillbody">{subSkillName?.trim()}</div>
                      <div className="Mt-medbody">
                        <div
                          className="Mt-medDotbody"
                          style={{
                            background: "#B7295A",
                            display: lowDisplay
                          }}
                        ></div>
                      </div>
                      <div className="Mt-medbody">
                        <div
                          className="Mt-medDotbody"
                          style={{
                            background: "#C4A200",
                            display: midDisplay
                          }}
                        ></div>
                      </div>
                      <div className="Mt-medbody">
                        <div
                          className="Mt-medDotbody"
                          style={{
                            background: "#00966D",
                            display: highDisplay
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
