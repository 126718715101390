import React from "react";
import Calling from "../../../assets/svg/Calling.svg";
import useLeadCapture from "../../../pages/UseLeadCapture";

const CallButton = () => {
  const phoneNumber = "7065937545"; // Replace with your pre-configured number
  const { handelAlerbarLead } = useLeadCapture();

  const handleCallClick = () => {
    const params = {
      source: "call_icon"
    };
    handelAlerbarLead(params);
    window.location.href = `tel:${phoneNumber}`;
  };

  return <img src={Calling} onClick={handleCallClick} alt="call" />;
};

export default CallButton;
